<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['LimitManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<router-link :to="'/' + this.$i18n.locale + '/account/limits/'">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</router-link>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('titles.editing_limit') }}</div>
										<div class="content__title--subtext">
											{{ $t('titles.create_new_limit_sub') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<!--<div class="form__line">
                    <div class="form__line--left">
                      <div class="form__line--title">
                        Наименование лимита
                      </div>
                    </div>
                    <div class="form__line--right">
                      <div class="col-xl-5">
                        <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.name ? 'input--error' : '']">
                          <input type="text" class="input-linck" required="" name="name" v-model="applications.name"  :placeholder="$t('headers.name')">
                        </div>
                        <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.name"><p v-for="(massage, massageInd) in errorMessage.messages.name" :key="massageInd">{{ massage }}</p></div>
                      </div>
                    </div>
                  </div>-->

									<div class="row">
										<div class="col-xl-12">
											<div class="form__line">
												<div class="form__line--left"></div>
												<div class="form__line--right">
													<div class="form__limit--row">
														<div class="col-lg-12">
															<div class="row">
																<div class="col-lg-6">
																	<div class="form__checkbox--chapter">
																		<div class="form__limit--btn">
																			<!--  form__limit--success -->
																			<div class="form__limit--icon">
																				<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.873 13.706c.35.103.688.248 1.004.43a7.167 7.167 0 007.346 0 4.659 4.659 0 014.586 0 7.435 7.435 0 007.382 0 4.66 4.66 0 014.587 0 7.614 7.614 0 003.726 1.004 6.951 6.951 0 003.584-1.004 4.589 4.589 0 011.003-.43 1.791 1.791 0 10-1.003-3.44 7.974 7.974 0 00-1.792.806 3.727 3.727 0 01-3.763 0 8.314 8.314 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.314 8.314 0 00-8.134 0 3.726 3.726 0 01-3.763 0 7.975 7.975 0 00-1.791-.806 1.792 1.792 0 00-1.004 3.44h-.036zm32.25 14.477a7.974 7.974 0 00-1.791.806 3.727 3.727 0 01-3.763 0 8.312 8.312 0 00-8.134 0 3.781 3.781 0 01-3.798 0 8.312 8.312 0 00-8.134 0 3.728 3.728 0 01-3.763 0 7.975 7.975 0 00-1.792-.806 1.79 1.79 0 10-1.003 3.44c.35.103.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.435 7.435 0 007.382 0 4.658 4.658 0 014.586 0 7.614 7.614 0 003.727 1.003 6.951 6.951 0 003.583-1.003 4.587 4.587 0 011.004-.43 1.791 1.791 0 10-1.004-3.44h-.036zm0-8.958a7.97 7.97 0 00-1.791.806 3.726 3.726 0 01-3.763 0 8.313 8.313 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.313 8.313 0 00-8.134 0 3.727 3.727 0 01-3.763 0 7.97 7.97 0 00-1.792-.806 1.792 1.792 0 00-2.365 1.218 1.792 1.792 0 001.219 2.222c.35.102.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.436 7.436 0 007.381 0 4.658 4.658 0 014.587 0 7.615 7.615 0 003.727 1.003 6.952 6.952 0 003.583-1.003 4.584 4.584 0 011.003-.43 1.792 1.792 0 00-1.003-3.44h.107z" fill="#fff"/></svg>
																			</div>
																			<div class="form__limit--text">
																				{{ $t('titles.create_new_limit_sub') }}
																			</div>
																			<router-link
																				:to="
																					'/' +
																					$i18n.locale +
																					'/account/limits/' +
																					applications.id +
																					'/republic'
																				"
																				>{{ $t('button.more') }}</router-link
																			>
																		</div>
																	</div>
																</div>
																<div class="col-lg-6">
																	<div class="form__checkbox--chapter">
																		<div class="form__limit--btn">
																			<div class="form__limit--icon">
																				<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.873 13.706c.35.103.688.248 1.004.43a7.167 7.167 0 007.346 0 4.659 4.659 0 014.586 0 7.435 7.435 0 007.382 0 4.66 4.66 0 014.587 0 7.614 7.614 0 003.726 1.004 6.951 6.951 0 003.584-1.004 4.589 4.589 0 011.003-.43 1.791 1.791 0 10-1.003-3.44 7.974 7.974 0 00-1.792.806 3.727 3.727 0 01-3.763 0 8.314 8.314 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.314 8.314 0 00-8.134 0 3.726 3.726 0 01-3.763 0 7.975 7.975 0 00-1.791-.806 1.792 1.792 0 00-1.004 3.44h-.036zm32.25 14.477a7.974 7.974 0 00-1.791.806 3.727 3.727 0 01-3.763 0 8.312 8.312 0 00-8.134 0 3.781 3.781 0 01-3.798 0 8.312 8.312 0 00-8.134 0 3.728 3.728 0 01-3.763 0 7.975 7.975 0 00-1.792-.806 1.79 1.79 0 10-1.003 3.44c.35.103.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.435 7.435 0 007.382 0 4.658 4.658 0 014.586 0 7.614 7.614 0 003.727 1.003 6.951 6.951 0 003.583-1.003 4.587 4.587 0 011.004-.43 1.791 1.791 0 10-1.004-3.44h-.036zm0-8.958a7.97 7.97 0 00-1.791.806 3.726 3.726 0 01-3.763 0 8.313 8.313 0 00-8.134 0 3.78 3.78 0 01-3.798 0 8.313 8.313 0 00-8.134 0 3.727 3.727 0 01-3.763 0 7.97 7.97 0 00-1.792-.806 1.792 1.792 0 00-2.365 1.218 1.792 1.792 0 001.219 2.222c.35.102.687.247 1.003.43a7.167 7.167 0 007.346 0 4.658 4.658 0 014.587 0 7.436 7.436 0 007.381 0 4.658 4.658 0 014.587 0 7.615 7.615 0 003.727 1.003 6.952 6.952 0 003.583-1.003 4.584 4.584 0 011.003-.43 1.792 1.792 0 00-1.003-3.44h.107z" fill="#fff"/></svg>
																			</div>
																			<div class="form__limit--text">
																				{{ $t('titles.create_new_limit_sub_2') }}
																			</div>
																			<router-link
																				:to="
																					'/' +
																					$i18n.locale +
																					'/account/limits/' +
																					applications.id +
																					'/local'
																				"
																				>{{ $t('button.more') }}</router-link
																			>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line" v-if="applications.comment">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="col-xl-12">
												<div class="form__line--title">
													Причина отказа - {{ applications.comment_at | formatDate }}
												</div>
												{{ applications.comment }}
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="col-xl-12">
												<div class="form__line">
													<div class="input__row">
														<a
															class="input-btn form__line--btn"
															@click="$modal.show('modal_head')"
															v-if="
																$store.getters.userPermission(['send_limit']) &&
																applications.status != 'approved_manager' &&
																applications.status != 'sent_manager' &&
																applications.status != 'check_manager' &&
																applications.status != 'active'
															"
															><!-- && applications.status != 'deleted' -->
															Отправить на проверку
														</a>
														<a
															class="input-btn form__line--btn"
															v-bind:disabled="applications.status != 'approved_manager'"
															v-if="
																$store.getters.userPermission(['send_limit']) &&
																applications.status != 'active' &&
																applications.status != 'deleted'
															"
															@click="
																applications.status == 'approved_manager' &&
																applications.status != 'sent_manager'
																	? $modal.show('modal_association')
																	: ''
															"
														>
															Отправить в ассоциацию
														</a>

														<a
															class="input-btn form__line--btn"
															@click="$modal.show('modal_success2')"
															v-if="
																$store.getters.userPermission(['check_limit']) &&
																applications.status != 'approved_manager' &&
																applications.status != 'check_manager' &&
																applications.status != 'new' &&
																applications.status != 'revoked' &&
																applications.status != 'active'
															"
														>
															Отправить на утверждение
														</a>

														<a
															class="input-btn form__line--btn"
															@click="$modal.show('modal_success')"
															v-if="
																$store.getters.userPermission(['approval_limit']) &&
																applications.status != 'approved_manager' &&
																applications.status == 'check_manager' &&
																applications.status != 'new' &&
																applications.status != 'revoked' &&
																applications.status != 'active'
															"
														>
															{{ $t('labels.approve') }}
														</a>

														<a
															class="input-btn form__line-error form__line--btn"
															@click="$modal.show('modal_delete')"
															v-if="
																$store.getters.userPermission(['approval_limit']) &&
																applications.status != 'new' &&
																applications.status != 'revoked' &&
																applications.status != 'active'
															"
														>
															Отклонить/Отправить на доработку
														</a>
													</div>
												</div>
												<div class="input__row">
													<button
														class="input-btn form__line--btn form__line--btn-blue"
														v-if="
															applications.status != 'approved_manager' &&
															applications.status != 'active' &&
															applications.status != 'deleted'
														"
													>
														{{ $t('button.save') }}
													</button>
													<a
														class="input-btn form__line-error form__line--btn"
														v-if="applications.status == 'sent_manager'"
														v-bind:disabled="applications.status != 'sent_manager'"
														@click="
															applications.status == 'sent_manager'
																? $modal.show('modal_revoke')
																: ''
														"
													>
														Отозвать
													</a>
													<!-- <a class="input-btn form__line--btn" @click="limitFile('xlsx')">
														{{ $t('button.download_excel') }}
														<img src="../../../assets/img/icon-file-w.svg" />
													</a> -->
												</div>

												<div
													class="form__block--line form__messages"
													v-if="message.status"
													:class="{
														'form__messages--error': message.status != 200,
														'form__messages--success': message.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ message.text }}
														</span>
													</label>
												</div>
												<div
													class="form__block--line form__messages"
													v-if="errorMessage.text"
													:class="{
														'form__messages--error': errorMessage.status != 200,
														'form__messages--success': errorMessage.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ errorMessage.text }}
														</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</form>

								<modal
									class="modal__block modal__block--middle modal__block--success"
									name="modal_head"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_head')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">
													Для утверждения лимита руководителем, необходимо подтверждение электронно
													цифровой подписью
												</div>
											</div>

											<div class="modal__content">
												<div class="modal__form">
													<div class="block__row">
														<div class="block__row--left">
															<a
																class="block__item block__item--green"
																@click="sentManager(applications.id)"
															>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																<!--Подписать ЭЦП-->
																Подтвердить
															</a>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_head')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</modal>
								<modal
									class="modal__block modal__block--middle modal__block--success"
									name="modal_association"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_association')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">
													Вы уверены что хотите отправить лимит
													<strong>
														{{ applications.name }}
													</strong>
													в ассоциацию
												</div>
											</div>
											<!--<form method="POST">-->
											<div class="modal__content">
												<div class="modal__form">
													<div class="form__line">
														<div class="form__line--right">
															<div class="form__line--title">
																Ссылка на статью на adilet.zan.kz
																<br />
																<br />
															</div>
															<div
																class="form__line--input"
																:class="[
																	errorMessage.messages && errorMessage.messages.comment
																		? 'input--error'
																		: '',
																]"
															>
																<input
																	type="text"
																	class="input-linck"
																	required=""
																	name="link"
																	v-model="applications.link"
																	placeholder="Ссылка на статью на adilet.zan.kz"
																/>
															</div>
															<div
																class="input-required"
																v-if="errorMessage.messages && errorMessage.messages.link"
															>
																<p
																	v-for="(massage, massageInd) in errorMessage.messages.link"
																	:key="massageInd"
																>
																	{{ massage }}
																</p>
															</div>
														</div>
													</div>

													<div class="block__row">
														<div class="block__row--left">
															<button
																class="block__item block__item--green"
																@click="sentAssociation(applications.id)"
															>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																Подписать ЭЦП
																<!--Oтправить-->
															</button>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_association')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
											<!--</form>-->
										</div>
									</div>
								</modal>

								<modal
									class="modal__block modal__block--middle modal__block--success"
									name="modal_success2"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_success2')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">
													Для утверждения лимита, необходимо подтверждение электронно цифровой
													подписью
												</div>
											</div>

											<div class="modal__content">
												<div class="modal__form">
													<div class="block__row">
														<div class="block__row--left">
															<a
																class="block__item block__item--green"
																@click="activeElement2(applications.id)"
															>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																<!--Подписать ЭЦП-->
																Подтвердить
															</a>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_success2')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</modal>
								<modal
									class="modal__block modal__block--middle modal__block--success"
									name="modal_success"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_success')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">
													Для утверждения лимита, необходимо подтверждение электронно цифровой
													подписью
												</div>
											</div>

											<div class="modal__content">
												<div class="modal__form">
													<div class="block__row">
														<div class="block__row--left">
															<a
																class="block__item block__item--green"
																@click="activeElement(applications.id)"
															>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																<!--Подписать ЭЦП-->
																Подтвердить
															</a>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_success')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</modal>
								<modal
									class="modal__block modal__block--middle modal__block--delete"
									name="modal_delete"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_delete')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">Напишите причину отклонения</div>
											</div>
											<form method="POST" @submit.prevent="blockElement">
												<div class="modal__content">
													<div class="modal__form">
														<div class="form__line">
															<div class="form__line--right">
																<div
																	class="form__line--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.comment
																			? 'input--error'
																			: '',
																	]"
																>
																	<textarea
																		class="input-linck"
																		required=""
																		name="comment"
																		:placeholder="$t('labels.reject_reason')"
																	></textarea>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.comment"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.comment"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>

														<div class="form__line">
															<div class="form__line--right">
																<div class="input__row input__row--center">
																	<button class="input-btn form__line--btn">Отправить</button>
																	<a
																		class="input-btn form__line--btn-black form__line--btn"
																		@click="$modal.hide('modal_delete')"
																	>
																		Отклонить
																	</a>
																</div>
															</div>
														</div>

														<div
															class="form__block--line form__messages"
															v-if="message.status"
															:class="{
																'form__messages--error': message.status != 200,
																'form__messages--success': message.status == 200,
															}"
														>
															<label class="form__block--input">
																<span class="form__block--title">
																	{{ message.text }}
																</span>
															</label>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</modal>

								<modal
									class="modal__block modal__block--middle modal__block--delete"
									name="modal_revoke"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_revoke')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">Отозвать лимит</div>
											</div>
											<div class="modal__content">
												<div class="modal__form">
													<div class="block__row">
														<div class="block__row--left">
															<a
																class="block__item block__item--red"
																@click="revokeElement(applications.id)"
															>
																<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																Отозвать
															</a>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_success')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>

													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</modal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			applications: null,

			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			ecp: false,
		}
	},

	computed: {},
	methods: {
		sentManager(id) {
			if (this.$configFile.active) return this.sendEcpKey(id, 'sent-manager')
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true

				handle()
			}
			websocket.onclose = (e) => {
				if (!e.wasClean) {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				if (data1.responseObject) {
					this.sendEcpKey(id, 'sent-manager')
				}
			}

			//this.sendEcpKey(id, 'sent-manager');
		},

		sendEcpKey(id, send) {
			this.message.status = null
			this.message.text = null
			this.message.texts = null

			api
				.post('limit/' + id + '/' + send, {
					data: this.dataEcp || this.$configFile.xml,
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/limits' })
					}
				})
		},

		sentAssociation(id) {
			if (this.$configFile.active) return this.sendEcpKey(id, 'activate')
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true

				handle()
			}
			websocket.onclose = (e) => {
				if (!e.wasClean) {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				if (data1.responseObject) {
					this.sendEcpKey(id, 'activate')
				}
			}

			/*const formData = new FormData(evt.target);
      api.post('limit/'+this.applications.id+'/activate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.status == 200) {
          this.message.status = 200;
          this.message.text = response.data.message;
          this.$router.push({path: '/'+ this.$i18n.locale +'/account/limits'});
        }
      });*/
		},
		revokeElement(id) {
			api
				.post('limit/' + id + '/revoked', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/limits' })
					}
				})
		},
		activeElement(id) {
			if (this.$configFile.active) return this.sendEcpKey(id, 'approved-manager')
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true
				handle()
			}
			websocket.onclose = (e) => {
				if (!e.wasClean) {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				if (data1.responseObject) {
					this.sendEcpKey(id, 'approved-manager')
				}
			}
			//this.sendEcpKey(id, 'approved-manager');
		},
		activeElement2(id) {
			if (this.$configFile.active) return this.sendEcpKey(id, 'check-manager')
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true
				handle()
			}
			websocket.onclose = (e) => {
				if (!e.wasClean) {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				if (data1.responseObject) {
					this.sendEcpKey(id, 'check-manager')
				}
			}
			//this.sendEcpKey(id, 'approved-manager');
		},

		blockElement(evt) {
			const formData = new FormData(evt.target)
			api
				.post('limit/' + this.applications.id + '/deactivate', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/limits' })
					}
				})
		},

		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			const formData = new FormData(evt.target)
			api
				.post('limit/' + this.$route.params.id, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},

		apiGetLimit() {
			api
				.get('limit/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.applications = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},

		limitFile(typeFile) {
			api
				.get('/limit/file', {
					responseType: 'arraybuffer',
					params: {
						item_id: this.$route.params.id,
						type: typeFile,
					},
				})
				.then(function (response) {
					let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
					let link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'download.xlsx'
					link.click()
				})
				.catch(() => {})
		},
	},
	beforeMount() {
		this.apiGetLimit()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>
